.header{
    margin-top: 60px;
}

.headerTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lora', serif;
    color: rgb(163, 11, 11);
    text-align: center;
}
.headerTitleSm{
    position: absolute;
    top: 9%;
    font-size: 20px;


}

.headerTitleLg{
    position: absolute;
    top: 9%;
    font-size: 100px;
}

.headerImg{
    width: 100%;
    height: 400px;
    margin-top: 80px;
    object-fit: cover;
}

