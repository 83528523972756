.register{
    height: calc(100vh - 50px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(
        rgba(255,255,255,0.5),
        rgba(255,255,255,0.5)
        ),
     url('https://static8.depositphotos.com/1523669/1001/i/450/depositphotos_10012930-stock-photo-3d-welcome.jpg' );
    background-size: cover;
}

.registerTitle{
    font-size: 50px;
}

.registerForm{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.registerForm > label{
    margin: 10px 0px;
}


.registerInput{
    padding: 10px;
    background-color: white;
    border: none;


}

.registerButton{
    margin-top: 20px;
    cursor: pointer;
    background-color: teal;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 10px;

}


.registerLoginButton{
    position: absolute;
    top: 60px;
    right: 20px;
    background-color:lightcoral;
    cursor: pointer;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
}