.singlepost{
    font: 9px;
    
}

.singlepostWrapper{
    padding: 20px;
    padding-right: 0;
}

.singlepostImg{
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
    max-height: 600px;
}
.singlePostTitle{
    text-align: center;
    margin: 10px;
    font-family: 'Lora',serif;
    font-size: 28px;
}



.singlePostEdit{
    float: right;
    font-size: 16px;

}

.singlePostIcon{
    margin-left: 10px;
    cursor: pointer;
}

.singlePostIcon:last-child{
color: rgb(205, 25, 12);
}

.singlePostInfo{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: #b39656;
    font-family:'varela Round',sans-serif;
}

.singlePostDesc{
    color: #666;
    font-size: 20px;
    line-height: 25px;
}

.singlePostDesc::first-letter{
    margin-left: 20px;
    font-size: 50px;
    font-weight: 600;
}


