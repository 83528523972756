.write{
    padding-top: 50px;
}

.writeForm{
position: relative;
}

.writeFormGroup{
    margin-left: 150px;

}

.writeIcon{
    width: 25px;
    height: 25px;
    border-radius: 50px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(142, 133, 133);
    cursor: pointer;
}


.writeInput{
    font-size: 30px;
    border: none;
    padding: 20px;
    width: 70vw;

    
}

.writeInput:focus{
    outline: none;
}

.writeText{
    font-size: 20px;
    height: 100vw;
}

.writeSubmit{
    position: absolute;
    top: 20px;
    right: 50px;
    color:white;
    background-color:teal ;
    padding: 10px;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    font-size:16px;
}


.writeImg{
    margin-left: 250px;
    width: 70vw;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
}