.settings{
    display: flex;
}

.settingsWrapper{
    flex: 9;
    padding: 20px;
}

.settingsTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settingsUpdateTitle{
font-size: 15px;
margin-bottom: 20px;
color: lightcoral;

}


.settingsDeleteTitle{
    color: red;
    font-size: 12px;
    cursor: pointer;
}

.settingsForm{
    display: flex;
    flex-direction: column;
}

.settingsPP{
    display: flex;
    align-items: center;
    margin:10px 0px;
}


.settingsPP > Img{
    width: 70px;
    height: 70px;
    border-radius: 20px;
    object-fit: cover;

}

.settingsPPIcon{
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background-color: lightcoral;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;

}


.settingsForm > label{
    font-size: 20px;
    margin-top: 20px;
}

.settingsForm > input{
    color: gray;
    margin: 10px 0;
    height: 20px;
    border: none;
    border-bottom: 1px solid lightgray;
}



.settingsSubmit{
    width: 150px;
    align-self: center;
    text-align: center;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: teal;
    padding: 10px;
    margin: 20px;
    cursor: pointer;

}