.top{
    width: 100%;
    height: 50px;
    background-color:rgb(158, 0, 0);
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 999;
}
.topLeft,.topRight{
    flex:3;
    display: flex;
    align-items: center;
    justify-content: center;

}

.topCenter{
    flex:6;
}

.topIcon{
    font-size: 20px;
    margin-right: 10px;
    color: rgb(249, 242, 242);
    cursor: pointer;
}

.topImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}


.topList{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.topListItem{
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
    color: white;
}


.topSearchIcon{
    font-size: 18px;
    color: rgb(243, 240, 240);
    cursor: pointer;
    margin-left: 50px;
}